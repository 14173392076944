import React, { useState, useEffect, useRef } from 'react';
import FacebookLogin from 'react-facebook-login';
import { useNavigate } from 'react-router-dom';
import FacebookLoginProps from 'react-facebook-login/dist/facebook-login-render-props';
// import GoogleLogin from 'react-google-login';
// import GoogleLoginComponent from './GoogleLogin';
import { GoogleLogin, useGoogleLogin } from '@react-oauth/google';

import {
  MDBNavbar,
  MDBContainer,
  MDBIcon,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBNavbarToggler,
  MDBNavbarBrand,
  MDBCollapse,
  MDBBtn,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardHeader,
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
  MDBRadio,
  MDBBtnGroup,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBTypography,
  MDBModalBody,
  MDBModalFooter,
  MDBInput,
  MDBInputGroup,
  MDBCardImage,
} from 'mdb-react-ui-kit';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { MultiSelect } from 'primereact/multiselect';
// import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import axios from 'axios';

import './styles.css';
import Footer from '../../components/Footer';
import ReduxCounter from '../../ReduxCounter';
import Select from 'react-select';
// import res from 'express/lib/response';

export default function Login() {
  const [basicModal, setBasicModal] = useState(false);
  const [basicModal2, setBasicModal2] = useState(false);
  const [basicModal3, setBasicModal3] = useState(false);
  const [fillActive, setFillActive] = useState('tab1');
  const [restaurants, setRestaurantDetails] = useState('');
  const [categories, setCategories] = useState([]);
  const [uniqueCategoriesFull, setUniqueCategoriesFull] = useState([]);
  const [menuItems, setMenuItems] = useState([]);
  const [currentOrder, setCurrentOrder] = useState([]);
  const [totalOrders, setTotalOrders] = useState([]);
  const [totalPrice, setTotalPrice] = useState([]);
  const [checkout_items, setCheckOutItems] = useState([]);
  const [toggleTwoModal, setToggleTwoModal] = useState(false);
  const [tableNumbers, setTableNumbers] = useState([]);
  const [reepayUrl, setReePayUrl] = useState('');
  const [userProfile, setUserProfile] = useState([]);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const googleSingIn = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      localStorage.setItem('return_url', window.location.origin);
      const data = `tokenid=${tokenResponse.access_token}`;
      const config = {
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
      };
      axios
        .post(`https://heywaitr-staging.herokuapp.com/api/user/auth/google/validate/web`, data, {
          config,
        })
        .then((response2) => {
          localStorage.setItem('user_profile', JSON.stringify(response2.data));
        })
        .catch((error) => {
          console.error('There was an error in calling api!', error);
        });
      window.location.replace(window.location.origin);
    },
  });

  const usernameChange = (value) => {
    setUsername(value);
  };
  const passwordChange = (value) => {
    setPassword(value);
  };
  const logIn = () => {
    const data = `email=${username}&password=${password}`;
    const config = {
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
    };
    axios
      .post(`https://heywaitr-staging.herokuapp.com/api/user/login`, data, { config })
      .then((response) => {
        if (response.data.Message == 'User successfully logged in') {
          setUserProfile(response.data);
          localStorage.setItem('user_profile', JSON.stringify(response.data));
          const return_url = localStorage.getItem('return_url');
          if (typeof return_url !== 'undefined' && return_url !== null) {
            window.location.replace(return_url);
          }
        } else {
          setUserProfile({ Error: 'Invalid Credentials' });
          notifyError();
        }
      })
      .catch((error) => {
        // element.parentElement.innerHTML = `Error: ${error.message}`;
        console.error('There was an error!', error);
        setUserProfile({ Error: 'Invalid Credentials' });
        notifyError();
      });
  };

  const notifyError = () => toast.error('Invalid Credentials!');
  const responseFacebook = (response) => {
    const data = `accesstoken=${response.accessToken}&facebookid=${response.id}`;
    const config = {
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
    };
    axios
      .post(`https://heywaitr-staging.herokuapp.com/api/user/auth/facebook/validate`, data, {
        config,
      })
      .then((response2) => {
        setUserProfile(response2.data);
        localStorage.setItem('user_profile', JSON.stringify(response2.data));
      })
      .catch((error) => {
        console.error('There was an error in calling api!', error);
      });
  };
  const responseGoogle = (response) => {
    console.log(response);
  };

  const fbLogin = () => {
    // FB.getLoginStatus(function(response) {
    //   fbLogin2(response);
    // });
  };
  return (
    <>
      <MDBContainer className="login_container" breakpoint="sm">
        <MDBRow className="detail_login">
          <MDBCol>
            <MDBCard alignment="center">
              <MDBCardBody>
                <a href="/api/redirect" className="signup_link">
                  <MDBIcon fas icon="chevron-left" />
                  &nbsp;Back to last page
                </a>
                <MDBCardTitle>
                  <MDBTypography tag="h3" className="mb-0 fw-bold">
                    Welcome Back
                  </MDBTypography>
                </MDBCardTitle>
                <MDBCardText>
                  Hello there, sign in to continue! or{' '}
                  <a className="signup_link" href="/register">
                    Create an Account
                  </a>
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
        <MDBRow className="detail_login">
          <MDBCol>
            <MDBCard>
              <MDBCardBody className="detail_login_input_form">
                {/* <MDBInputGroup textBefore='Contact Number' className='mb-3'> */}
                {/* <label for="username">Username</label> */}
                <input
                  id="username"
                  className="form-control"
                  type="text"
                  placeholder="Email"
                  onChange={(e) => usernameChange(e.target.value)}
                />
                {/* <label for="password">Password</label> */}
                <input
                  id="password"
                  className="form-control"
                  type="password"
                  placeholder="Password"
                  onChange={(e) => passwordChange(e.target.value)}
                />
                {/* </MDBInputGroup> */}
                <MDBBtn
                  className="btn-lg detail_login_submit"
                  // color='none'
                  onClick={() => logIn()}
                >
                  Sign In
                </MDBBtn>
                <p style={{ textAlign: 'center' }}>
                  <MDBTypography>
                    <MDBTypography className="forgotpassword" tag="small">
                      <a href="/forgot">Forgot Password</a>
                    </MDBTypography>
                  </MDBTypography>
                </p>
                <ToastContainer
                  position="top-center"
                  autoClose={1000}
                  hideProgressBar={true}
                  newestOnTop={false}
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                  className="toasterror"
                />

                <MDBRow>
                  <MDBCol>
                    <MDBTypography tag="small">
                      <hr />
                    </MDBTypography>
                  </MDBCol>
                  <MDBCol>
                    <MDBTypography tag="small">
                      <p style={{ textAlign: 'center' }}>or</p>
                    </MDBTypography>
                  </MDBCol>
                  <MDBCol>
                    <MDBTypography tag="small">
                      <hr />
                    </MDBTypography>
                  </MDBCol>
                </MDBRow>

                {/* <FacebookLogin
                        appId="2799368613681742"
                        autoLoad={true}
                        fields="name,email,picture"
                        // onClick={componentClicked}
                        cssClass="my-facebook-button-class"
                        callback={responseFacebook} /> */}

                {/* <MDBBtn onClick={() => fbLogin()} rounded className='text-dark sign_in_others fs-6' color='light'>
                        <MDBIcon fab icon="facebook-square fs-4" style={{float:"left"}}/><span>Connect with Facebook</span>
                      </MDBBtn> */}
                <FacebookLoginProps
                  appId="2799368613681742"
                  autoLoad={false}
                  fields="name,email,picture"
                  redirectUri="/success"
                  callback={responseFacebook}
                  render={(renderProps) => (
                    <MDBBtn
                      onClick={renderProps.onClick}
                      rounded
                      className="text-dark sign_in_others fs-6"
                      color="light"
                    >
                      <MDBIcon fab icon="facebook-square fs-4" style={{ float: 'left' }} />
                      <span>Connect with Facebook</span>
                    </MDBBtn>
                  )}
                />
                {/* <GoogleLogin
                        clientId="356128359500-hmkgrgcckeo25hi8g2rmsfhl1tkci1ue.apps.googleusercontent.com"
                        buttonText="Login"
                        onSuccess={responseGoogle}
                        onFailure={responseGoogle}
                        // cookiePolicy={'single_host_origin'} 
                        uxMode="redirect"
                        isSignedIn={true}    

                      />       */}
                {/* <GoogleLoginComponent />                                                                     */}

                {/* <GoogleLogin
  onSuccess={credentialResponse => {
    console.log(credentialResponse);
  }}
  onError={() => {
    console.log('Login Failed');
  }}
/>; */}
                <MDBBtn
                  onClick={() => googleSingIn()}
                  rounded
                  className="text-dark sign_in_others fs-6"
                  color="light"
                >
                  <MDBIcon fab icon="google fs-4" style={{ float: 'left' }} />
                  <span>Connect with Google</span>
                </MDBBtn>

                <MDBBtn disabled rounded className="text-dark sign_in_others fs-6" color="light">
                  <MDBIcon fab icon="apple fs-4" style={{ float: 'left' }} />
                  <span>Continue with Apple</span>
                </MDBBtn>
                <MDBBtn disabled rounded className="text-dark sign_in_others fs-6" color="light">
                  <span>Continue as Guest</span>
                </MDBBtn>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  );
}
