import React from 'react';
import { useLottie } from 'lottie-react';
import processing from './lottie-120435-document-error.json';

const ProcessingOrder = () => {
  const options = {
    animationData: processing,
    loop: true,
  };

  const { View } = useLottie(options);

  return <>{View}</>;
};

export default ProcessingOrder;
