import React, { useState, useEffect, useRef } from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import {
  MDBNavbar,
  MDBContainer,
  MDBIcon,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBNavbarToggler,
  MDBNavbarBrand,
  MDBCollapse,
  MDBCheckbox,
  MDBBtn,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardHeader,
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
  MDBRadio,
  MDBBtnGroup,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBTypography,
  MDBModalBody,
  MDBModalFooter,
  MDBInput,
  MDBInputGroup,
  MDBCardImage,
} from 'mdb-react-ui-kit';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

import './styles.css';

export default function Register() {
  const [basicModal, setBasicModal] = useState(false);
  const [basicModal2, setBasicModal2] = useState(false);
  const [basicModal3, setBasicModal3] = useState(false);
  const [fillActive, setFillActive] = useState('tab1');
  const [restaurants, setRestaurantDetails] = useState('');
  const [categories, setCategories] = useState([]);
  const [uniqueCategoriesFull, setUniqueCategoriesFull] = useState([]);
  const [menuItems, setMenuItems] = useState([]);
  const [currentOrder, setCurrentOrder] = useState([]);
  const [totalOrders, setTotalOrders] = useState([]);
  const [totalPrice, setTotalPrice] = useState([]);
  const [checkout_items, setCheckOutItems] = useState([]);
  const [toggleTwoModal, setToggleTwoModal] = useState(false);
  const [tableNumbers, setTableNumbers] = useState([]);
  const [reepayUrl, setReePayUrl] = useState('');
  const [userProfile, setUserProfile] = useState([]);
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [contact, setContact] = useState('');
  const [password, setPassword] = useState('');
  const [confirm_password, setConfirmPassword] = useState('');
  const [agree_to_terms, setAgreeTerms] = useState('');

  const usernameChange = (value) => {
    setUsername(value);
  };
  const emailChange = (value) => {
    setEmail(value);
  };
  const contactChange = (value) => {
    setContact(value);
  };
  const passwordChange = (value) => {
    setPassword(value);
  };
  const confirmPasswordChange = (value) => {
    setConfirmPassword(value);
  };
  const agreeTermsChange = (value) => {
    setAgreeTerms(value);
  };
  const signUp = () => {
    const data = `email=${username}&password=${password}`;
    const config = {
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
    };
    axios
      .post(`https://heywaitr-staging.herokuapp.com/api/user/login`, data, { config })
      .then((response) => {
        if (response.data.Message == 'User successfully logged in') {
          setUserProfile(response.data);
          localStorage.setItem('user_profile', JSON.stringify(response.data));
          const return_url = localStorage.getItem('return_url');
          if (typeof return_url !== 'undefined' && return_url !== null) {
            // window.location.replace(return_url);
          }
        } else {
          setUserProfile({ Error: 'Invalid Credentials' });
          notifyError();
        }
      })
      .catch((error) => {
        // element.parentElement.innerHTML = `Error: ${error.message}`;
        console.error('There was an error!', error);
        setUserProfile({ Error: 'Invalid Credentials' });
        notifyError();
      });
    // console.log(username);
    // console.log(password);
    console.log(localStorage.getItem('return_to_restaurant'));
    console.log(userProfile);
  };

  const notifyError = () => toast.error('Invalid Credentials!');
  const closeModal3 = () => {
    setBasicModal3(!basicModal3);
  };
  const responseFacebook = (response) => {
    const data = `accesstoken=${response.accessToken}&facebookid=${response.id}`;
    const config = {
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
    };
    axios
      .post(`https://heywaitr-staging.herokuapp.com/api/user/auth/facebook/validate`, data, {
        config,
      })
      .then((response2) => {
        setUserProfile(response2.data);
        localStorage.setItem('user_profile', JSON.stringify(response2.data));
      })
      .catch((error) => {
        console.error('There was an error in calling api!', error);
      });
  };

  return (
    <>
      <MDBContainer className="signin_container" breakpoint="sm">
        <MDBRow className="detail_signin">
          <MDBCol>
            <MDBCard alignment="center">
              <MDBCardBody>
                <a href="/api/redirect" className="signup_link">
                  <MDBIcon fas icon="chevron-left" />
                  &nbsp;Back to last page
                </a>
                <MDBCardTitle>
                  <MDBTypography tag="h3" className="mb-0 fw-bold">
                    Hello! Create Account
                  </MDBTypography>
                </MDBCardTitle>
                <MDBCardText>
                  Already have an account?{' '}
                  <a className="signup_link" href="/login">
                    Sign in
                  </a>
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
        <MDBRow className="detail_signin">
          <MDBCol>
            <MDBCard>
              <MDBCardBody className="detail_signin_input_form">
                <input
                  id="username"
                  className="form-control"
                  type="text"
                  placeholder="Name"
                  onChange={(e) => usernameChange(e.target.value)}
                />
                <input
                  id="email"
                  className="form-control"
                  type="text"
                  placeholder="Email"
                  onChange={(e) => emailChange(e.target.value)}
                />
                <input
                  id="contact"
                  className="form-control"
                  type="text"
                  placeholder="Contact number"
                  onChange={(e) => contactChange(e.target.value)}
                />
                <input
                  id="password"
                  className="form-control"
                  type="password"
                  placeholder="Password"
                  onChange={(e) => passwordChange(e.target.value)}
                />
                <input
                  id="confirm_password"
                  className="form-control"
                  type="password"
                  placeholder="Confirm Password"
                  onChange={(e) => confirmPasswordChange(e.target.value)}
                />
                <input
                  id="agree_to_terms"
                  type="checkbox"
                  onChange={(e) => agreeTermsChange(e.target.value)}
                />
                <label for="agree_to_terms">
                  &nbsp;By creating an account, you agree to our <a href="">Terms and Conditions</a>
                </label>
                <MDBBtn
                  className="btn-lg detail_signin_submit"
                  // color='none'
                  onClick={() => signUp()}
                >
                  Sign Up
                </MDBBtn>
                <p style={{ textAlign: 'center' }}>
                  {/* <MDBTypography>
                          <MDBTypography className="forgotpassword" tag='small'><a href="/forgot">Forgot Password</a></MDBTypography>
                        </MDBTypography> */}
                </p>
                <ToastContainer
                  position="top-center"
                  autoClose={1000}
                  hideProgressBar={true}
                  newestOnTop={false}
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                  className="toasterror"
                />

                <MDBRow>
                  <MDBCol>
                    <MDBTypography tag="small">
                      <hr />
                    </MDBTypography>
                  </MDBCol>
                  <MDBCol>
                    <MDBTypography tag="small">
                      <p style={{ textAlign: 'center' }}>or</p>
                    </MDBTypography>
                  </MDBCol>
                  <MDBCol>
                    <MDBTypography tag="small">
                      <hr />
                    </MDBTypography>
                  </MDBCol>
                </MDBRow>

                <FacebookLogin
                  appId="2799368613681742"
                  autoLoad={false}
                  fields="name,email,picture"
                  callback={responseFacebook}
                  render={(renderProps) => (
                    <MDBBtn
                      onClick={renderProps.onClick}
                      rounded
                      className="text-dark sign_in_others fs-6"
                      color="light"
                    >
                      <MDBIcon fab icon="facebook-square fs-4" style={{ float: 'left' }} />
                      <span>Connect with Facebook</span>
                    </MDBBtn>
                  )}
                />
                <MDBBtn rounded className="text-dark sign_in_others fs-6" color="light">
                  <MDBIcon fab icon="google fs-4" style={{ float: 'left' }} />
                  <span>Connect with Google</span>
                </MDBBtn>
                <MDBBtn rounded className="text-dark sign_in_others fs-6" color="light">
                  <MDBIcon fab icon="apple fs-4" style={{ float: 'left' }} />
                  <span>Continue with Apple</span>
                </MDBBtn>
                <MDBBtn rounded className="text-dark sign_in_others fs-6" color="light">
                  <span>Continue as Guest</span>
                </MDBBtn>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  );
}
