import React, { useState, useEffect, useRef } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function ApiRedirect() {
  const notifyError = () => toast('Redirecting to previous transaction page');

  const getRedirectedUrl = () => {
    const return_url = localStorage.getItem('return_url');
    if (typeof return_url !== 'undefined' && return_url !== null) {
      window.location.replace(return_url + '' + window.location.search);
    }
  };

  useEffect(() => {
    getRedirectedUrl();
  }, []);

  return (
    <>
      <p>Redirecting to Previous Page</p>
    </>
  );
}
