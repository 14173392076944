import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/database';
import 'firebase/compat/app-check';
import { initializeAppCheck, ReCaptchaEnterpriseProvider, getToken } from 'firebase/app-check';

const firebaseConfig = {
  apiKey: 'AIzaSyDzsU2paHuB9rkVzaPTWR4Foxzp3yciRjU',
  authDomain: 'heywaitr.firebaseapp.com',
  databaseURL: 'https://heywaitr.firebaseio.com',
  projectId: 'heywaitr',
  storageBucket: 'heywaitr.appspot.com',
  messagingSenderId: '824346593925',
  appId: '1:824346593925:web:311b96ec06429d54f777ec',
  measurementId: 'G-M2SBLF4MM1',
};

if (!firebase.apps.length && typeof window !== 'undefined') {
  const app = firebase.initializeApp(firebaseConfig);
}

if (!firebase.apps.length && typeof window !== 'undefined') {
  firebase.analytics();
}

export { firebase };
