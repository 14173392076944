import EllipsisText from 'react-ellipsis-text';
import axios from 'axios';
import React, { Component, useState, useEffect } from 'react';
import { GoogleLogin, useGoogleLogin, googleLogout } from '@react-oauth/google';
import {
  MDBNavbar,
  MDBContainer,
  MDBIcon,
  MDBNavbarLink,
  MDBBtn,
  MDBTypography,
  MDBRow,
  MDBCol,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBCard,
  MDBCardText,
  MDBCardBody,
  MDBCardTitle,
} from 'mdb-react-ui-kit';
import './styles.css';
import Footer from '../../components/Footer';
import configData from '../../config.json';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NavBar from '../../components/Navbar';

export default function Profile() {
  const [userProfile, setUserProfile] = useState(JSON.parse(localStorage.getItem('user_profile')));
  const [basicModal3, setBasicModal3] = useState(false);
  const [theLanguage, setTheLanguage] = useState('en');
  const [passwordmodal, setpasswordmodal] = useState(false);

  useEffect(() => {
    const cachelang = window.localStorage.getItem('user_lang');
    if (cachelang) {
      setTheLanguage(cachelang);
    }
  }, []);

  const changeLanguage = (language) => {
    setTheLanguage(language);
    window.localStorage.setItem('user_lang', language);
  };

  const logOut = (response) => {
    // console.log('log out');
    setUserProfile([]);
    localStorage.removeItem('user_profile');
    googleLogout();
    window.location.replace('/');
  };

  const toggleShow2 = () => {
    setBasicModal3(!basicModal3);
  };

  const handleChangePhoto = async (file) => {
    const image = file;
    let reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onload = async () => {
      try {
        let image_data = {
          filename: image.name,
          image: reader.result,
        };
        let response = await axios({
          method: 'POST',
          url: `${configData.SERVER_URL}/upload`,
          data: image_data,
          headers: {
            Authorization: `Bearer ${userProfile.token}`,
          },
          validateStatus: (status) => {
            return true;
          },
        });

        handleChange('avatar', response?.data?.url);
      } catch (ex) {
        console.log(ex?.response?.data);
      }
    };
  };

  const handleChange = (key, value) => {
    setUserProfile({
      ...userProfile,
      profile: {
        ...(userProfile.profile || {}),
        [key]: value,
      },
    });
  };

  const saveProfile = async () => {
    try {
      const response = await axios({
        method: 'PUT',
        url: `${configData.SERVER_URL}/user/edit`,
        data: userProfile.profile,
        headers: {
          Authorization: `Bearer ${userProfile.token}`,
        },
      });

      toast.success('Profil oplysninger er gemt');

      window.localStorage.setItem('user_profile', JSON.stringify(userProfile));
    } catch (ex) {
      console.log(ex?.response?.data);
    }
  };

  const togglepasswordmodal = () => {
    setpasswordmodal(!passwordmodal);
  };

  return (
    <>
      <NavBar />

      {/* -----NEW----- */}

      <MDBContainer breakpoint="md" className="profile mt-4">
        <h4 className="p-2">Generel information</h4>

        <p className="mt-16 p-2">
          Venligst udfyld din profil med dit navn, e-mailadresse og telefonnummer, sä vi kan
          kontakte dig i tilfalde af andringer i dine order eller sporgsmäl om din konto. Dette
          hjalper os med at give dig en bedre service og sikre, at du altid har adgang til din konto
        </p>

        <MDBRow className="mb-3 newloginmodal form mt-16">
          <MDBCol sm="6" className="p-4">
            <div className="form-group  ">
              <label>Fornavn og efternavn</label>
              <input
                type="text"
                className="form-control"
                value={userProfile.profile.name}
                onChange={(e) => {
                  handleChange('name', e.target.value);
                }}
              />
            </div>

            <div className="form-group mt-8">
              <label>E-mailadresse</label>
              <input
                type="email"
                className="form-control"
                value={userProfile.profile.email}
                onChange={(e) => {
                  handleChange('email', e.target.value);
                }}
              />
            </div>

            <div className="form-group mt-8">
              <label>Mobiltelefonnummer</label>
              <input
                type="text"
                className="form-control"
                value={
                  userProfile.profile.provider === 'Local'
                    ? `${userProfile.profile.mobilenumber}`
                    : 'n/a'
                }
                onChange={(e) => {
                  handleChange('mobilenumber', e.target.value);
                }}
              />
            </div>
          </MDBCol>

          <MDBCol sm="6" className="relative p-4">
            <div className="form-group">
              <label>Sprog</label>
              <MDBRow className="profile_labels_row   ">
                <MDBCol sm="12" className="">
                  <MDBBtn
                    className="language_btn langselect"
                    onClick={() => {
                      changeLanguage('da');
                    }}
                  >
                    <MDBTypography>
                      <input
                        className="input_radio"
                        id="language2"
                        name="language2"
                        type="radio"
                        value="english"
                      />
                      {theLanguage == 'da' ? (
                        <label for="language2">
                          <MDBCol className="language-selector">Danish &nbsp; &nbsp; </MDBCol>
                          <MDBCol style={{ float: 'right' }}>
                            <MDBIcon
                              style={{ color: '#88B11A' }}
                              size="1x"
                              fas
                              icon="check-circle"
                            />
                          </MDBCol>
                        </label>
                      ) : (
                        <label for="language2">
                          <MDBCol className="language-selector">Danish &nbsp; &nbsp; </MDBCol>
                          <MDBCol style={{ float: 'right' }}>
                            <MDBIcon
                              style={{ color: '#88B11A' }}
                              size="1x"
                              className="notSelected"
                              fas
                              icon="circle"
                            />
                          </MDBCol>
                        </label>
                      )}
                    </MDBTypography>
                  </MDBBtn>
                </MDBCol>
              </MDBRow>
              <MDBRow className="profile_labels_row  mt-2">
                <MDBCol sm="12" className="">
                  <MDBBtn
                    className="language_btn langselect"
                    onClick={() => {
                      changeLanguage('en');
                    }}
                  >
                    <MDBTypography>
                      <input
                        className="input_radio"
                        id="language1"
                        name="language1"
                        type="radio"
                        value="english"
                      />
                      {theLanguage == 'en' ? (
                        <label for="language1">
                          <MDBCol className="language-selector">English &nbsp; &nbsp; </MDBCol>
                          <MDBCol style={{ float: 'right' }}>
                            <MDBIcon
                              style={{ color: '#88B11A' }}
                              size="1x"
                              fas
                              icon="check-circle"
                            />
                          </MDBCol>
                        </label>
                      ) : (
                        <label for="language1">
                          <MDBCol className="language-selector">English &nbsp; &nbsp; </MDBCol>
                          <MDBCol style={{ float: 'right' }}>
                            <MDBIcon
                              style={{ color: '#88B11A' }}
                              size="1x"
                              className="notSelected"
                              fas
                              icon="circle"
                            />
                          </MDBCol>
                        </label>
                      )}
                    </MDBTypography>
                  </MDBBtn>
                </MDBCol>
              </MDBRow>
            </div>

            <MDBBtn className="submit_btn saveBtn" onClick={saveProfile}>
              Gem informationer
            </MDBBtn>
          </MDBCol>
        </MDBRow>

        <h4 className="mt-16 p-2">Profilbillede</h4>

        <MDBRow className="mb-3 form mt-8">
          <MDBCol sm="6" className="p-4">
            <MDBRow className="mb-4">
              <MDBCol lg={5}>
                <div
                  className="profileplaceholder"
                  style={{
                    backgroundImage: `url(${userProfile.profile.avatar})`,
                  }}
                />
              </MDBCol>
              <MDBCol lg={7} className="changeprofbtndiv mt-4">
                <MDBBtn
                  className="submit_btn w-100 btnheight"
                  onClick={(e) => document.getElementById('my_photo').click()}
                >
                  Skift billede
                </MDBBtn>
                <input
                  type="file"
                  name="my_photo"
                  id="my_photo"
                  className="upload_input"
                  onChange={(event) => {
                    handleChangePhoto(event.target.files[0]);
                  }}
                />
              </MDBCol>
            </MDBRow>
          </MDBCol>
          <MDBCol sm="6" className="p-4">
            {' '}
            <p>
              Vi anbefaler, at du uploader et profilbillede, sà vores tjenere og personale kan
              genkende dig, nar du besoger vores steder.
            </p>
            <p>
              Dette vil halpe med at give en mere personlig serviceoplevelse og sikre, at dine order
              og anmodninger behandles hurtigt og effektivt.
            </p>
          </MDBCol>
        </MDBRow>

        <h4 className="mt-16 p-2">Skift kodeord</h4>
        <MDBRow className="mb-3 form mt-8">
          <MDBCol sm="6" className="mb-4 p-4">
            <MDBBtn className="submit_btn w-100 btnheight" onClick={togglepasswordmodal}>
              Skift kodeord
            </MDBBtn>
          </MDBCol>
          <MDBCol sm="6" className="p-4">
            <p>
              Her kan du valge at andre dit kodeord ved at indtaste det game kodeord og derefter
              indtaste det nye kodeord to gange for at bekrafte andringen. Dette er for at sikre, at
              kun du har adgang til din konto.
            </p>
          </MDBCol>
        </MDBRow>
      </MDBContainer>

      <MDBRow className="navigation_bar_small">
        <MDBCol size="4">
          <a href="/">
            <MDBIcon fas icon="home" />
          </a>
        </MDBCol>
        <MDBCol size="4">
          <a href="/restaurants">
            <MDBIcon fas icon="utensils" />
          </a>
        </MDBCol>
        <MDBCol size="4">
          {(localStorage.getItem('user_profile') !== undefined &&
            localStorage.getItem('user_profile') !== null) |
          (userProfile.length !== 0 && userProfile !== null) ? (
            <a href="/profile">
              <MDBIcon fas icon="user-alt" />
            </a>
          ) : (
            <MDBIcon fas icon="user-alt" />
          )}
        </MDBCol>
      </MDBRow>

      <Footer />

      <ChangePasswordModal
        open={passwordmodal}
        setpasswordmodal={setpasswordmodal}
        toggle={togglepasswordmodal}
        userProfile={userProfile}
      />
    </>
  );
}

const ChangePasswordModal = (props) => {
  const { open, toggle, setpasswordmodal, userProfile } = props;

  const [data, setdata] = useState({
    currentpassword: '',
    newpassword: '',
    confirmpassword: '',
  });

  useEffect(() => {
    if (!open) {
      setdata({
        currentpassword: '',
        newpassword: '',
        confirmpassword: '',
      });
    }
  }, [open]);

  const handleChange = (key, value) => {
    setdata({
      ...data,
      [key]: value,
    });
  };

  const updatePasswordNow = async (e) => {
    try {
      e.preventDefault();
      if (data.newpassword != data.confirmpassword) {
        toast.error('Kodeord stemmer ikke overens');

        return;
      }

      await axios({
        method: 'PUT',
        url: `${configData.SERVER_URL}/user/password/change`,
        data: {
          password: data.currentpassword,
          newpassword: data.newpassword,
        },
        headers: {
          Authorization: `Bearer ${userProfile.token}`,
        },
      });

      toast.success('Kodeord er opdateret');
    } catch (ex) {
      const message =
        ex?.response?.data?.Message || 'Unable to change password. Please contact administrator.';

      toast.error(message, {});
    }

    console.log(data);
  };

  return (
    <MDBModal show={open} setShow={setpasswordmodal} tabIndex="-1">
      <MDBModalDialog centered>
        <MDBModalContent>
          <MDBModalHeader className="payment_headerx">
            <MDBModalTitle className="desktop_modals">
              &nbsp;&nbsp;&nbsp;Skift kodeord
            </MDBModalTitle>
            <MDBBtn className="btn-close" color="none" onClick={toggle}></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody className="payment_header_body profile">
            <MDBCard className="remove_box_shadow">
              <form onSubmit={updatePasswordNow}>
                <MDBCardBody className="paymentbreakdown">
                  <MDBRow className="mb-3">
                    <MDBCol>
                      <MDBRow className="profile_labels_row">
                        <MDBCol size="12" className="profile_labels">
                          <MDBTypography>
                            <label for="new_password">Nuværende koderord</label>
                          </MDBTypography>
                        </MDBCol>
                      </MDBRow>
                      <MDBRow className="profile_labels_row">
                        <MDBCol size="12">
                          <MDBTypography>
                            <input
                              className="input_text"
                              id="new_password"
                              name="new_password"
                              type="password"
                              value={data.currentpassword}
                              onChange={(e) => handleChange('currentpassword', e.target.value)}
                              required
                            />
                          </MDBTypography>
                        </MDBCol>
                      </MDBRow>
                      <MDBRow className="profile_labels_row">
                        <MDBCol size="12" className="profile_labels">
                          <MDBTypography>
                            <label for="new_password">Nyt kodeord</label>
                          </MDBTypography>
                        </MDBCol>
                      </MDBRow>
                      <MDBRow className="profile_labels_row">
                        <MDBCol size="12">
                          <MDBTypography>
                            <input
                              className="input_text"
                              id="new_password"
                              name="new_password"
                              type="password"
                              value={data.newpassword}
                              onChange={(e) => handleChange('newpassword', e.target.value)}
                              required
                            />
                          </MDBTypography>
                        </MDBCol>
                      </MDBRow>
                      <MDBRow className="profile_labels_row">
                        <MDBCol size="12" className="profile_labels">
                          <MDBTypography>
                            <label for="confirm_password">Bekræft nyt kodeord </label>
                          </MDBTypography>
                        </MDBCol>
                      </MDBRow>
                      <MDBRow>
                        <MDBCol size="12">
                          <MDBTypography>
                            <input
                              className="input_text"
                              id="confirm_password"
                              name="confirm_password"
                              type="password"
                              value={data.confirmpassword}
                              onChange={(e) => handleChange('confirmpassword', e.target.value)}
                              required
                            />
                          </MDBTypography>
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>
                  </MDBRow>

                  <MDBCardText style={{ textAlign: 'center' }}>
                    <MDBBtn
                      className="placeorder placeorder3"
                      style={{ width: '100%', height: '40px', padding: 0 }}
                    >
                      Skift kodeord
                    </MDBBtn>
                  </MDBCardText>
                </MDBCardBody>
              </form>
            </MDBCard>
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
};
