import {createStore, combineReducers} from 'redux';

import initialData from './initialData';
import {counterReducer} from './reducers';

import { loadState, saveState } from './localStorage'

const reducers = combineReducers({
    cart: counterReducer
});


// export default createStore(
//     (state, action) => reducers(state, action),
//     initialData
// );



const persistedState = loadState();
const store = createStore(
    reducers,
  persistedState
);
store.subscribe(() => {
  saveState({
    cart: store.getState().cart
  });
});

export default store;