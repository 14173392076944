import React, { useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.logToDB(error, errorInfo);
  }

  async logToDB(error, errorInfo) {
    try {
      let user = {};
      let userCache = window.localStorage.getItem('user_profile');
      if (userCache) {
        user = JSON.parse(userCache).profile;
      }

      const data = {
        error: error.toString() || '',
        errorInfo: errorInfo || {},
        user: user || {},
        date: new Date(),
        address: window.location.href,
      };

      firebase.firestore().collection('web-error-logs').add(data);
    } catch (ex) {
      console.log('Logging component error =>' + ex.message);
    }
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <>
          <style jsx>{`
            div {
              background: #fff;
              margin: 0 auto;
              width: 500px;
              margin-top: 20vh;
              padding: 2rem;
              text-align: center;
              color: #161752;
            }

            h1 {
              font-weight: bold;
              font-size: 20px;
            }
          `}</style>
          <div>
            <h1>Something went wrong.</h1>
            Please contact administrator.
          </div>
        </>
      );
    }

    return this.props.children;
  }
}
