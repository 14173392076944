import EllipsisText from 'react-ellipsis-text';
import axios from 'axios';
import React, { Component, useState, useEffect } from 'react';
import configData from '../../config.json';
import { GoogleLogin, useGoogleLogin, googleLogout } from '@react-oauth/google';
import moment from 'moment';
import {
  MDBNavbar,
  MDBContainer,
  MDBIcon,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBNavbarToggler,
  MDBNavbarBrand,
  MDBCollapse,
  MDBBtn,
  MDBTypography,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBInput,
  MDBCardImage,
} from 'mdb-react-ui-kit';

import './styles.css';
import Footer from '../../components/Footer';
import NavBar from '../../components/Navbar';

// JSON.parse(localStorage.getItem('user_profile'))
export default function Orders() {
  const [userProfile, setUserProfile] = useState(JSON.parse(localStorage.getItem('user_profile')));
  const [myOrdersDineIn, setMyOrdersDineIn] = useState([]);
  const [myOrdersTakeOut, setMyOrdersTakeOut] = useState([]);
  const [basicModal3, setBasicModal3] = useState(false);
  const [toggleTwoModal, setToggleTwoModal] = useState(false);

  useEffect(() => {
    fetchMyOrders();
  }, []);

  const fetchMyOrders = () => {
    if (userProfile !== undefined && userProfile !== null && userProfile.profile) {
      axios({
        method: 'GET',
        url: `${configData.SERVER_URL}/orders/fetch/recent?forDinein=true&startdate=&enddate=&keyword=`,
        data: null,
        headers: {
          Authorization: `Bearer ${userProfile.token}`,
        },
      }).then((response) => {
        console.log(response.data);
        setMyOrdersDineIn(response.data);
      });
      axios({
        method: 'GET',
        url: `${configData.SERVER_URL}/orders/fetch/recent?forDinein=false&startdate=&enddate=&keyword=`,
        data: null,
        headers: {
          Authorization: `Bearer ${userProfile.token}`,
        },
      }).then((response) => {
        setMyOrdersTakeOut(response.data);
      });
    }
  };

  const logOut = (response) => {
    console.log('log out');
    setUserProfile([]);
    localStorage.removeItem('user_profile');
    googleLogout();
    window.location.replace('/');
  };

  const toggleShow2 = () => {
    setBasicModal3(!basicModal3);
  };

  const closeModal3 = () => {
    setBasicModal3(!basicModal3);
  };
  return (
    <>
      <NavBar />

      <MDBContainer breakpoint="md" className="orders">
        <MDBTypography variant="h3" className="heading">
          Your Active Orders
        </MDBTypography>
        <MDBRow className="mb-3">
          <MDBCol md="6">
            <MDBRow>
              <MDBTypography className="profile_name">Dine in</MDBTypography>
            </MDBRow>
            <MDBRow>
              <MDBTypography className="profile_name">
                <hr />
              </MDBTypography>
            </MDBRow>
            <MDBRow>
              {myOrdersDineIn.map((myOrder) => (
                <>
                  <MDBCol md="6" className="orderlist">
                    <MDBCard className="cardborder">
                      <MDBCardBody>
                        <MDBRow>
                          <MDBCol>
                            <img src={myOrder.cover} className="rounded restaurant_cover" alt="" />
                          </MDBCol>
                        </MDBRow>
                        <MDBRow>
                          <MDBCol>
                            <MDBCard className="details">
                              <MDBCardBody>
                                <MDBCol className="cola">
                                  <MDBRow>
                                    <MDBTypography className="fw-bold">
                                      {myOrder.restaurant}
                                    </MDBTypography>
                                  </MDBRow>
                                  <MDBRow>
                                    <MDBTypography>
                                      {moment(Date.parse(myOrder.date)).format('MMMM Do YYYY')}
                                    </MDBTypography>
                                  </MDBRow>
                                  <MDBRow>
                                    <MDBTypography>Order ID: {myOrder?.refNo}</MDBTypography>
                                  </MDBRow>
                                </MDBCol>
                                <MDBCol className="colb">
                                  <MDBRow>
                                    <MDBTypography tag="strong" className="goGreen">
                                      {myOrder.currency + ' ' + myOrder.subTotalAmount}
                                    </MDBTypography>
                                  </MDBRow>
                                </MDBCol>
                              </MDBCardBody>
                            </MDBCard>
                          </MDBCol>
                        </MDBRow>
                        <MDBRow>
                          <MDBCol>
                            <MDBCard className="breakdown">
                              <MDBCardBody>
                                {myOrder.items.map((item) => (
                                  <>
                                    <MDBRow>
                                      <MDBCol>
                                        <MDBTypography tag="strong">
                                          {item.qty + 'x ' + item.name}
                                        </MDBTypography>
                                      </MDBCol>
                                      <MDBCol className="goRight">
                                        <MDBTypography tag="strong">
                                          {myOrder.currency + ' ' + item.price}
                                        </MDBTypography>
                                      </MDBCol>
                                    </MDBRow>
                                    {item.extras.length > 0 ? (
                                      <MDBRow>
                                        <MDBCol className="goRight">
                                          <MDBTypography tag="small">Extras Added</MDBTypography>
                                        </MDBCol>
                                      </MDBRow>
                                    ) : (
                                      ''
                                    )}
                                  </>
                                ))}

                                <MDBRow>
                                  <MDBCol>
                                    <MDBTypography tag="strong">
                                      <hr />
                                    </MDBTypography>
                                  </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                  <MDBCol>
                                    <MDBTypography tag="strong">Service Fee</MDBTypography>
                                  </MDBCol>
                                  <MDBCol className="goRight">
                                    <MDBTypography tag="strong">
                                      {myOrder.serviceFee !== null &&
                                      myOrder.serviceFee !== undefined ? (
                                        <>{myOrder.currency + ' ' + myOrder.serviceFee}</>
                                      ) : (
                                        'N/A'
                                      )}
                                    </MDBTypography>
                                  </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                  <MDBCol>
                                    <MDBTypography tag="strong">
                                      <hr />
                                    </MDBTypography>
                                  </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                  <MDBCol>
                                    <MDBTypography tag="strong">Tip</MDBTypography>
                                  </MDBCol>
                                  <MDBCol className="goRight">
                                    <MDBTypography tag="strong">
                                      {myOrder.currency + ' ' + myOrder.tipAmount}
                                    </MDBTypography>
                                  </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                  <MDBCol>
                                    <MDBTypography tag="strong">
                                      <hr />
                                    </MDBTypography>
                                  </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                  <MDBCol>
                                    <MDBTypography tag="strong">Total</MDBTypography>
                                  </MDBCol>
                                  <MDBCol className="goRight">
                                    <MDBTypography tag="strong" className="goGreen">
                                      {myOrder.currency + ' ' + myOrder.totalAmount}
                                    </MDBTypography>
                                  </MDBCol>
                                </MDBRow>
                              </MDBCardBody>
                            </MDBCard>
                            {/* <MDBRow>
                              <MDBCol>
                                <MDBBtn
                                  className="reorder"
                                  onClick={() => {
                                    // setToggleTwoModal(!toggleTwoModal);
                                  }}
                                >
                                  Re-order
                                </MDBBtn>
                              </MDBCol>
                            </MDBRow> */}
                          </MDBCol>
                        </MDBRow>
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCol>
                </>
              ))}
            </MDBRow>
          </MDBCol>

          <MDBCol md="6">
            <MDBRow>
              <MDBTypography className="profile_name">Take Out</MDBTypography>
            </MDBRow>
            <MDBRow>
              <MDBTypography className="profile_name">
                <hr />
              </MDBTypography>
            </MDBRow>
            <MDBRow>
              {myOrdersTakeOut.map((myOrder) => (
                <>
                  <MDBCol md="6" className="orderlist">
                    <MDBCard className="cardborder">
                      <MDBCardBody>
                        <MDBRow>
                          <MDBCol>
                            <img src={myOrder.cover} className="rounded restaurant_cover" alt="" />
                          </MDBCol>
                        </MDBRow>
                        <MDBRow>
                          <MDBCol>
                            <MDBCard className="details">
                              <MDBCardBody>
                                <MDBCol className="cola">
                                  <MDBRow>
                                    <MDBTypography className="fw-bold">
                                      {myOrder.restaurant}
                                    </MDBTypography>
                                  </MDBRow>
                                  <MDBRow>
                                    <MDBTypography>
                                      {moment(Date.parse(myOrder.date)).format('MMMM Do YYYY')}
                                    </MDBTypography>
                                  </MDBRow>
                                  <MDBRow>
                                    <MDBTypography>Order ID: {myOrder?.refNo}</MDBTypography>
                                  </MDBRow>
                                </MDBCol>
                                <MDBCol className="colb">
                                  <MDBRow>
                                    <MDBTypography tag="strong" className="goGreen">
                                      {myOrder.currency + ' ' + myOrder.subTotalAmount}
                                    </MDBTypography>
                                  </MDBRow>
                                </MDBCol>
                              </MDBCardBody>
                            </MDBCard>
                          </MDBCol>
                        </MDBRow>
                        <MDBRow>
                          <MDBCol>
                            <MDBCard className="breakdown">
                              <MDBCardBody>
                                {myOrder.items.map((item) => (
                                  <>
                                    <MDBRow>
                                      <MDBCol>
                                        <MDBTypography tag="strong">
                                          {item.qty + 'x ' + item.name}
                                        </MDBTypography>
                                      </MDBCol>
                                      <MDBCol className="goRight">
                                        <MDBTypography tag="strong">
                                          {myOrder.currency + ' ' + item.price}
                                        </MDBTypography>
                                      </MDBCol>
                                    </MDBRow>
                                    {item.extras.length > 0 ? (
                                      <MDBRow>
                                        <MDBCol className="goRight">
                                          <MDBTypography tag="small">Extras Added</MDBTypography>
                                        </MDBCol>
                                      </MDBRow>
                                    ) : (
                                      ''
                                    )}
                                  </>
                                ))}

                                <MDBRow>
                                  <MDBCol>
                                    <MDBTypography tag="strong">
                                      <hr />
                                    </MDBTypography>
                                  </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                  <MDBCol>
                                    <MDBTypography tag="strong">Service Fee</MDBTypography>
                                  </MDBCol>
                                  <MDBCol className="goRight">
                                    <MDBTypography tag="strong">
                                      {myOrder.serviceFee !== null &&
                                      myOrder.serviceFee !== undefined ? (
                                        <>{myOrder.currency + ' ' + myOrder.serviceFee}</>
                                      ) : (
                                        'N/A'
                                      )}
                                    </MDBTypography>
                                  </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                  <MDBCol>
                                    <MDBTypography tag="strong">
                                      <hr />
                                    </MDBTypography>
                                  </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                  <MDBCol>
                                    <MDBTypography tag="strong">Tip</MDBTypography>
                                  </MDBCol>
                                  <MDBCol className="goRight">
                                    <MDBTypography tag="strong">
                                      {myOrder.currency + ' ' + myOrder.tipAmount}
                                    </MDBTypography>
                                  </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                  <MDBCol>
                                    <MDBTypography tag="strong">
                                      <hr />
                                    </MDBTypography>
                                  </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                  <MDBCol>
                                    <MDBTypography tag="strong">Total</MDBTypography>
                                  </MDBCol>
                                  <MDBCol className="goRight">
                                    <MDBTypography tag="strong" className="goGreen">
                                      {myOrder.currency + ' ' + myOrder.totalAmount}
                                    </MDBTypography>
                                  </MDBCol>
                                </MDBRow>
                              </MDBCardBody>
                            </MDBCard>
                            {/* <MDBRow>
                              <MDBCol>
                                <MDBBtn
                                  className="reorder"
                                  onClick={() => {
                                    // changePickupDate('later');
                                  }}
                                >
                                  Re-order
                                </MDBBtn>
                              </MDBCol>
                            </MDBRow> */}
                          </MDBCol>
                        </MDBRow>
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCol>
                </>
              ))}
            </MDBRow>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol className="submit_btn_class">
            <MDBBtn className="submit_btn">View Order History</MDBBtn>
          </MDBCol>
        </MDBRow>
      </MDBContainer>

      <MDBRow className="navigation_bar_small">
        <MDBCol size="4">
          <a href="/">
            <MDBIcon fas icon="home" />
          </a>
        </MDBCol>
        <MDBCol size="4">
          <a href="/restaurants">
            <MDBIcon fas icon="utensils" />
          </a>
        </MDBCol>
        <MDBCol size="4">
          {(localStorage.getItem('user_profile') !== undefined &&
            localStorage.getItem('user_profile') !== null) |
          (userProfile?.length !== 0 && userProfile !== null) ? (
            <a href="/profile">
              <MDBIcon fas icon="user-alt" />
            </a>
          ) : (
            <MDBIcon fas icon="user-alt" />
          )}
        </MDBCol>
      </MDBRow>

      <Footer />

      <MDBModal show={toggleTwoModal} setShow={setToggleTwoModal} tabIndex="-1">
        <MDBModalDialog centered>
          <MDBModalContent>
            <MDBModalHeader className="payment_headerx">
              <MDBModalTitle className="desktop_modals">
                <MDBIcon
                  // onClick={() => {
                  //   setToggleTwoModal(!toggleTwoModal);
                  //   setTimeout(() => {
                  //     setBasicModal2(!basicModal2);
                  //   }, 400);
                  // }}
                  fas
                  icon="angle-left"
                />
                &nbsp;&nbsp;&nbsp;Payment
              </MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody className="payment_header_body">
              {/* <MDBCard className="remove_box_shadow">
                <MDBCardBody className="select_pay_method">
                  <MDBCardTitle className="paytitle select_pay_method_title"> 
                    <img
                      src="/icon-order.png"
                      className="dine_or_pickup"
                      alt="..."
                      style={{ paddingLeft: '0' }}
                    />
                    &nbsp;&nbsp;Select your payment method
                  </MDBCardTitle>
                  <MDBRow className="mobile_pay_row">
                    <MDBCard className="remove_box_shadow">
                      <MDBCardBody className="payment_method_select">
                        <MDBCardTitle>
                          <input
                            onChange={(e) => cardChange(e.target.value)}
                            type="radio"
                            name="card_payment"
                            id="mobile_pay"
                            value="mobile_pay"
                          />
                          <label for="mobile_pay">
                            <img src="/mobile_pay.png" height="40" alt="" loading="lazy" />
                            &nbsp; &nbsp; Mobile Pay
                          </label>
                          <br />
                        </MDBCardTitle>
                      </MDBCardBody>
                    </MDBCard>
                  </MDBRow>
                  {Object.entries(mycards).length === 0
                    ? ' '
                    : mycards.cards.map((card) => (
                        <>
                          <MDBRow>
                            <MDBCard className="remove_box_shadow">
                              <MDBCardBody className="payment_method_select">
                                <MDBCardTitle>
                                  <input
                                    onChange={(e) => cardChange(e.target.value)}
                                    type="radio"
                                    name="card_payment"
                                    id={card.id}
                                    value={card.id}
                                  />
                                  <label for={card.id}>
                                    &nbsp;&nbsp; 
                                    {card.card_type.toUpperCase() == 'VISA' ? (
                                      <img src="/visa.png" height="40" alt="" loading="lazy" />
                                    ) : (
                                      <MDBIcon far icon="credit-card" />
                                    )}
                                    &nbsp; &nbsp; &nbsp; &nbsp;{card.masked_card} 
                                  </label>
                                  <br />
                                </MDBCardTitle>
                              </MDBCardBody>
                            </MDBCard>
                          </MDBRow>
                        </>
                      ))}

                  <MDBCardText className="enteranewcard" style={{ textAlign: 'center' }}>
                    <a 
                      className="placeorder placeorder2"
                      onClick={() => addCard()}
                      style={{ width: '100%' }}
                    >
                      Enter a New Card
                    </a>
                  </MDBCardText> 
                </MDBCardBody>
              </MDBCard> */}

              <MDBCard className="tips remove_box_shadow">
                <MDBCardBody className="tipsbody">
                  <MDBCardTitle className=" ">
                    <img
                      src="/icon-tip.png"
                      className="dine_or_pickup"
                      alt="..."
                      style={{ paddingLeft: '0' }}
                    />{' '}
                    &nbsp;&nbsp;Give Tip
                  </MDBCardTitle>

                  {/* <MDBCol>
                    <MDBBtnGroup
                      style={{ display: 'inline-block', transition: 'none' }}
                      className="tip_choices"
                    >
                      <MDBRadio
                        btn
                        btnColor="light"
                        id="no_tip"
                        onClick={(e) => tipChange(e.target)}
                        value="0"
                        name="tip"
                        wrapperTag="span"
                        label="None"
                        defaultChecked
                      />
                      {restaurantTips.map((tip, v) => (
                        <>
                          <MDBRadio
                            btn
                            btnColor="light"
                            id={tip._id}
                            onClick={(e) => tipChange(e.target)}
                            value={tip.tipPercentage}
                            name="tip"
                            wrapperTag="span"
                            label={tip.name}
                          />
                        </>
                      ))}
                    </MDBBtnGroup>
                  </MDBCol> */}
                </MDBCardBody>
              </MDBCard>

              <MDBCard className="remove_box_shadow">
                <MDBCardBody className="paymentbreakdown">
                  <MDBCardTitle className="paytitle">
                    <img
                      src="/icon-payment.png"
                      className="dine_or_pickup"
                      alt="..."
                      style={{ paddingLeft: '0' }}
                    />{' '}
                    &nbsp;&nbsp;Payment Breakdown
                  </MDBCardTitle>
                  <MDBCardText>
                    <MDBCardText style={{ fontWeight: 'bold' }}>
                      {/* {totalOrders.length} items */}
                    </MDBCardText>
                    {/* </MDBCardText>  */}
                    {/* <MDBRow className="sidebar_checkout_card_row">
                      {totalOrders.map((x, v) => (
                        <>
                          <MDBRow className="main_breakdown"> 
                            <MDBCol className="breakdown_main">
                              {' '}
                              <MDBTypography tag="strong">
                                {x.orders[5] + 'x  ' + x.orders[1]}{' '}
                              </MDBTypography>
                            </MDBCol> 
                            <MDBCol className="breakdown_price">
                              DKK&nbsp;{roundToTwo(x.orders[5] * x.orders[2])}
                            </MDBCol>
                          </MDBRow>
                          {x.orders[7].map((a, b) => (
                            <>
                              {typeof a._id == 'string' ? (
                                <>
                                  <MDBRow>
                                    <MDBCol className="breakdown_main">{a.name}</MDBCol>
                                  </MDBRow>
                                  {a.options.map((c, d) =>
                                    c.quantity > 0 ? (
                                      <>
                                        <MDBRow>
                                          <MDBCol className="breakdown_main breakdown_main2">
                                            {c.name}
                                          </MDBCol>
                                          <MDBCol className="breakdown_price">
                                            {roundToTwo(x.orders[5] * c.additionalPrice)}
                                          </MDBCol>
                                        </MDBRow>
                                        {x.orders[7].map((e, f) => (
                                          <>
                                            {typeof e._id == 'number' &&
                                            !e.hasOwnProperty('isMultiple') ? (
                                              <>
                                                {e.belongsto == c._id
                                                  ? e.options.map((g, h) =>
                                                      g.quantity > 0 ? (
                                                        <MDBRow className="breakdown_main">
                                                          <MDBCol className="breakdown_main breakdown_main3">
                                                            {g.name}
                                                          </MDBCol>
                                                          <MDBCol className="breakdown_price">
                                                            {roundToTwo(x.orders[5] * g.Price)}
                                                          </MDBCol>
                                                        </MDBRow>
                                                      ) : (
                                                        ''
                                                      ),
                                                    )
                                                  : ''}
                                              </>
                                            ) : (
                                              ''
                                            )}
                                          </>
                                        ))}
                                      </>
                                    ) : (
                                      ''
                                    ),
                                  )}
                                </>
                              ) : (
                                ''
                              )}
                              {typeof a._id == 'number' && a.hasOwnProperty('isMultiple')
                                ? a.isMultiple == false
                                  ? a.options.map((k, l) =>
                                      k.quantity > 0 ? (
                                        <MDBRow>
                                          <MDBCol className="breakdown_main breakdown_main2">
                                            {k.name}
                                          </MDBCol>
                                          <MDBCol className="breakdown_price">
                                            {roundToTwo(x.orders[5] * k.additionalPrice)}
                                          </MDBCol>
                                        </MDBRow>
                                      ) : (
                                        ''
                                      ),
                                    )
                                  : ''
                                : ''}
                            </>
                          ))}
                          <br />
                          {x.orders[6].map((a, b) => (
                            <>
                              {a.quantity > 0 ? (
                                <>
                                  <MDBRow className="extras_breakdown">
                                    <MDBCol className="breakdown_extra">
                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                      {a.quantity > 0 ? a.name + ` (${a.quantity})` : ''}
                                    </MDBCol>
                                    <MDBCol className="breakdown_price">
                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;DKK&nbsp;
                                      {roundToTwo(x.orders[5] * a.price * a.quantity)}
                                    </MDBCol>
                                  </MDBRow>
                                  <br />
                                </>
                              ) : (
                                ''
                              )}
                            </>
                          ))} 
                        </>
                      ))}
                    </MDBRow> */}
                    <hr />
                    <MDBCardText style={{ fontWeight: 'bold' }}>
                      Tip: <span className="summary_totals">{/* {tipPrice} */}</span>
                    </MDBCardText>
                    <MDBCardText style={{ fontWeight: 'bold' }}>
                      Service Fee: <span className="summary_totals">None</span>
                    </MDBCardText>
                    <MDBRow>
                      <MDBCol size="10">
                        <MDBCardText style={{ fontWeight: 'bold' }}>Total:&nbsp;</MDBCardText>
                      </MDBCol>
                      <MDBCol size="2">
                        <MDBCardText
                          style={{ fontWeight: 'bold', float: 'right', color: '#95C11F' }}
                        >
                          {/* {totalPrice + tipPrice} */}
                        </MDBCardText>
                      </MDBCol>
                    </MDBRow>

                    <MDBCardText style={{ textAlign: 'center' }}>
                      <MDBBtn
                        className="placeorder placeorder3"
                        style={{ width: '100%' }}
                        // onClick={() => payOrder()}
                        // onClick={() => {
                        //   if (card_to_use.length < 1) {
                        //     validateForm('card');
                        //   } else {
                        //     payOrder();
                        //   }
                        // }}
                      >
                        Proceed to Payment
                      </MDBBtn>

                      {/* {validCard ? (
                        ''
                      ) : (
                        <>
                          <MDBCardText className="validation_error">
                            Please input a valid payment method
                          </MDBCardText>
                        </>
                      )} */}
                    </MDBCardText>
                  </MDBCardText>
                </MDBCardBody>
              </MDBCard>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}
