import React, { useEffect, useState, createContext, useContext } from 'react';

export const RestaurantContext = createContext();

export function RestaurantWrapper({ children }) {
  const [fetching, setFetching] = useState(true);
  const [restaurants, setRestaurants] = useState([]);

  return (
    <RestaurantContext.Provider
      value={{
        restaurants,
        setRestaurants,
        fetching,
        setFetching,
      }}
    >
      {children}
    </RestaurantContext.Provider>
  );
}

export function useRestaurantContext() {
  return useContext(RestaurantContext);
}
