import React, { useState } from 'react';
import {
  MDBNavbar,
  MDBContainer,
  MDBIcon,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBNavbarToggler,
  MDBNavbarBrand,
  MDBCollapse,
  MDBBtn,
  MDBTypography,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
} from 'mdb-react-ui-kit';

import './styles.css';
import Footer from '../../components/Footer';

export default function TopNav() {
  // const [showNavColor, setShowNavColor] = useState(false);
  const [showNavColorSecond, setShowNavColorSecond] = useState(false);
  // const [showNavColorThird, setShowNavColorThird] = useState(false);

  return (
    <>
      <MDBNavbar className="landing_navbar" expand="lg" bgColor="#9BC22C">
        <MDBContainer fluid>
          <MDBNavbarBrand href="#">
            <img src="/logo.png" height="120" alt="" loading="lazy" />
          </MDBNavbarBrand>
          <MDBNavbarToggler
            type="button"
            data-target="#navbarColor02"
            aria-controls="navbarColor02"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={() => setShowNavColorSecond(!showNavColorSecond)}
          >
            <MDBIcon icon="bars" fas />
          </MDBNavbarToggler>
          <MDBCollapse show={showNavColorSecond} navbar id="navbarColor02">
            <MDBNavbarNav className=".me-auto mb-2 mb-lg-0 d-flex flex-row">
              <MDBNavbarItem className="active">
                <MDBNavbarLink aria-current="page" href="#">
                  Start
                </MDBNavbarLink>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <MDBNavbarLink href="/restaurants">Restaurants</MDBNavbarLink>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <MDBNavbarLink href="#">Contact</MDBNavbarLink>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <MDBNavbarLink href="#">Contact</MDBNavbarLink>
              </MDBNavbarItem>
            </MDBNavbarNav>
            <MDBBtn outline color="white" className="me-2" type="button">
              Get Free Demo <MDBIcon fas icon="angle-right" />
            </MDBBtn>
          </MDBCollapse>
        </MDBContainer>
        <div className="food">
          <h1>Discover great food</h1>
          <h6 className="delicious">Find delicious stuff from the best restaurants around you</h6>
          <MDBBtn outline color="white" className="me-2" type="button">
            Get Free Demo <MDBIcon fas icon="angle-right" />
          </MDBBtn>
        </div>
        <div classNames="landing_phone">
          <img src="frontpage-mobile-phones.png" className="phone img-fluid" alt="..." />
        </div>
      </MDBNavbar>

      <MDBContainer className="paragraph1_container">
        <div class="d-flex align-items-start  mb-3 ">
          <MDBCol className="paragraph1">
            <MDBTypography tag="h2" className="mb-0">
              <strong>
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Cum doloremque officia
                laboriosam. Itaque ex obcaecati architecto! Qui necessitatibus delectus placeat illo
                rem id nisi consequatur esse, sint perspiciatis soluta porro?
              </strong>
            </MDBTypography>
            <MDBTypography className="lead mb-0">
              Find delicious stuff from the best restaurants around you.
            </MDBTypography>
          </MDBCol>
          <MDBCol>&nbsp;</MDBCol>
        </div>
      </MDBContainer>

      <MDBContainer className="paragraph2_container">
        <div class="d-flex align-items-start  mb-3 ">
          <MDBCol>
            <img
              src="/frontpage-intro-bottom.png"
              className="img-fluid "
              alt=""
              style={{ maxWidth: '24rem' }}
            />
          </MDBCol>
          <MDBCol className="paragraph2">
            <MDBTypography tag="h2" className="mb-0">
              <strong>Breeze thru the ordering and payment easily</strong>
            </MDBTypography>
            <MDBTypography className="lead mb-0">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              incididunt ut labore et dolore magna aliqua. Fermentum dui faucibus in ornare. Iaculis
              eu non diam phasellus vestibulum lorem sed.
            </MDBTypography>
          </MDBCol>
        </div>
      </MDBContainer>

      <MDBContainer className="paragraph3_container">
        <div class="d-flex align-items-start  mb-3 ">
          <MDBCol>
            <img
              src="Group 619.svg"
              className="img-fluid icon_landing"
              alt=""
              style={{ maxWidth: '24rem' }}
            />
            <MDBCard style={{ maxWidth: '22rem' }} alignment="center">
              <MDBCardBody>
                <MDBCardTitle>Discover great food</MDBCardTitle>
                <MDBCardText>
                  Find delicious stuff from the best restaurants around you.
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol>
            <img
              src="Group 583.svg"
              className="img-fluid icon_landing"
              alt=""
              style={{ maxWidth: '24rem' }}
            />
            <MDBCard style={{ maxWidth: '22rem' }}>
              <MDBCardBody>
                <MDBCardTitle>Build your order easily</MDBCardTitle>
                <MDBCardText>
                  Drool over some yummy pics, maybe add some drinks and extras...
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol>
            <img
              src="Group 628.svg"
              className="img-fluid icon_landing"
              alt=""
              style={{ maxWidth: '24rem' }}
            />
            <MDBCard style={{ maxWidth: '22rem' }}>
              <MDBCardBody>
                <MDBCardTitle>Enjoy eating</MDBCardTitle>
                <MDBCardText>
                  Drool over some yummy pics, maybe add some drinks and extras...
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </div>
      </MDBContainer>

      <MDBContainer className="paragraph3_demo text-center">
        <MDBBtn outline color="black" className="me-2" type="button">
          Get Free Demo <MDBIcon fas icon="angle-right" />
        </MDBBtn>
      </MDBContainer>

      <MDBContainer className="paragraph4_container">
        <div class="d-flex align-items-start  mb-3 ">
          <MDBCol className="paragraph4">
            <MDBTypography tag="h2" className="mb-0">
              <strong>The best restaurants are just around you.</strong>
            </MDBTypography>
            <MDBTypography className="lead mb-0">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              incididunt ut labore et dolore magna aliqua. Fermentum dui faucibus in ornare. Iaculis
              eu non diam phasellus vestibulum lorem sed.
            </MDBTypography>
            <MDBBtn outline color="black" className="me-2" type="button">
              Get Free Demo <MDBIcon fas icon="angle-right" />
            </MDBBtn>
          </MDBCol>
          <MDBCol>
            <img src="Image 2.png" className="img-fluid shadow-4" alt="..." />
          </MDBCol>
        </div>
      </MDBContainer>

      <Footer />
    </>
  );
}
