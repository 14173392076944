import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage, deleteToken, isSupported } from 'firebase/messaging';

var firebaseConfig = {
  apiKey: 'AIzaSyDzsU2paHuB9rkVzaPTWR4Foxzp3yciRjU',
  authDomain: 'heywaitr.firebaseapp.com',
  databaseURL: 'https://heywaitr.firebaseio.com',
  projectId: 'heywaitr',
  storageBucket: 'heywaitr.appspot.com',
  messagingSenderId: '824346593925',
  appId: '1:824346593925:web:311b96ec06429d54f777ec',
  measurementId: 'G-M2SBLF4MM1',
};

const firebaseApp = initializeApp(firebaseConfig);
let messaging;
try {
  messaging = getMessaging();
} catch (err) {
  console.error('Failed to initialize Firebase Messaging', err);
}

export const fetchToken = () => {
  return new Promise((resolve, reject) => {
    getToken(messaging, {
      vapidKey:
        'BOtW9_jZ7L5JpDYDxf_7HVN8PUSRJmNsnhTAW8IenIWKDiJLOvRdW0oVJYvbtx5hFi36EpALEtKRE1MJCP7FakI',
    })
      .then((currentToken) => {
        if (currentToken) {
          resolve(currentToken);
          // setTokenFound(true);
          // Track the token -> client mapping, by sending to backend server
          // show on the UI that permission is secured
        } else {
          resolve('');
          console.log('No registration token available. Request permission to generate one.');
          // setTokenFound(false);
          // shows on the UI that permission is required
        }
      })
      .catch((err) => {
        resolve('');
        console.log('An error occurred while retrieving token. ', err);
        // catch error while creating client token
      });
  });
};

export const deleteUserToken = () => {
  deleteToken(messaging);
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

const isPushSupported = () => {
  return isSupported();
};

export { messaging, isPushSupported };
