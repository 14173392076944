import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import './index.css';
import LandingPage from './pages/LandingPage';
import Restaurants from './pages/Restaurants';
// import reportWebVitals from './reportWebVitals';
import Detail from './pages/MenuDetails';
import Login from './pages/Login';
import Register from './pages/Register';
import Profile from './pages/Profile';
import Orders from './pages/Orders';
import { Provider } from 'react-redux';
import store from './store';
import ApiRedirect from './ApiReturnUrl';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { NotificationWrapper } from './context/notification';
import { OrderWrapper } from './context/order';
import { RestaurantWrapper } from './context/restaurants';
import ErrorBoundaries from './components/ErrorBoundary';
import Survey from './pages/Survey';
import Survey2 from './pages/Survey/page2';
import './firebase';

// Request interceptors for API calls
axios.interceptors.request.use(
  (config) => {
    config.headers['accept-language'] = `${localStorage.getItem('user_lang')}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export default function Home() {
  return (
    <ErrorBoundaries>
      <NotificationWrapper>
        <OrderWrapper>
          <RestaurantWrapper>
            <BrowserRouter>
              <Routes>
                <Route exact path="/" element={<Restaurants />}></Route>
                <Route exact path="/restaurants" element={<Restaurants />} />
                <Route exact path="/start" element={<LandingPage />} />
                <Route exact path="/restaurants/:id" element={<Detail />} />
                <Route exact path="/api/redirect" element={<ApiRedirect />} />
                <Route exact path="/login" element={<Login />} />
                <Route exact path="/register" element={<Register />} />
                <Route exact path="/profile" element={<Profile />} />
                <Route exact path="/orders" element={<Orders />} />
                <Route exact path="/survey" element={<Survey />} />
                <Route exact path="/survey-2" element={<Survey2 />} />
                {/* https://heywaitr-staging.herokuapp.com/api/order/acceptaddcard?id=cs_e9159d4d0ca6cbd4642ab8e44a94eec8&customer=6238877c5af15f00162f28e9&payment_method=ca_2dd1a65bdb68704955b43829c6c9b226 */}
              </Routes>
              <ToastContainer
                position="bottom-center"
                autoClose={2000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
              />
            </BrowserRouter>
          </RestaurantWrapper>
        </OrderWrapper>
      </NotificationWrapper>
    </ErrorBoundaries>
  );
}

// ReactDOM.render(<Provider store={store}><Home/></Provider>, document.getElementById("root"));
// ReactDOM.render(<GoogleOAuthProvider clientId="356128359500-hmkgrgcckeo25hi8g2rmsfhl1tkci1ue.apps.googleusercontent.com"><Home/></GoogleOAuthProvider>, document.getElementById("root"));
ReactDOM.render(
  <GoogleOAuthProvider clientId="824346593925-cb7tb47r3duqvo4vhm0ehkepbkprcblr.apps.googleusercontent.com">
    <Home />
  </GoogleOAuthProvider>,
  document.getElementById('root'),
);

// menu_title
