import React, { useState, useEffect, useRef, useLayoutEffect, useMemo } from 'react';
import { MDBSpinner, MDBModal, MDBModalBody } from 'mdb-react-ui-kit';

function Loader() {
  return (
    <MDBModal show staticBackdrop>
      <MDBModalBody>
        <MDBSpinner className="loaderstyle" />
      </MDBModalBody>
    </MDBModal>
  );
}

export default Loader;
