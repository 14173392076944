import React, { Component, useState, useEffect, useLayoutEffect } from 'react';
import {
  MDBNavbar,
  MDBContainer,
  MDBIcon,
  MDBNavbarLink,
  MDBBtn,
  MDBTypography,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBInput,
} from 'mdb-react-ui-kit';
import './styles.css';
import moment from 'moment';
import axios from 'axios';
import configData from '../../config.json';

function Survey(props) {
  useLayoutEffect(() => {
    try {
      document.body.style.backgroundColor = '#EDEDED';
    } catch {}
  }, []);

  useEffect(() => {
    getInfo();
  }, []);

  const [mainRating, setMainRating] = useState({
    name: 'Main Rating',
    value: '',
  });
  const [categories, setCategories] = useState([
    {
      name: 'Mad',
      value: '',
    },
    {
      name: 'Service',
      value: '',
    },
    {
      name: 'Ventetid',
      value: '',
    },
    {
      name: 'Bestillingsoplevelse',
      value: '',
    },
  ]);

  const [userInfo, setUserInfo] = useState({});
  const [success, setSuccess] = useState(false);
  const [invalid, setInvalid] = useState(false);

  //actual ratings data of the user
  const [userData, setUserData] = useState({});

  useEffect(() => {
    if (userInfo.orderid) {
      checkValidity();
    }
  }, [userInfo.orderid]);

  const getInfo = () => {
    const params = new URLSearchParams(window.location.search);

    const orderid = params.get('orderid');
    const restaurantid = params.get('restaurantid');
    const restaurantname = params.get('restaurantname');
    const refno = params.get('refno');
    const name = params.get('name');
    const orderdate = params.get('orderdate');
    const mainrating = params.get('mainrating');

    if (!orderid) {
      setInvalid(true);
    }

    if (mainrating) {
      setUserData({
        ...userData,
        'Main Rating': parseInt(mainrating),
      });
    }

    setUserInfo({
      orderid,
      restaurantid,
      restaurantname,
      refno,
      name,
      orderdate,
    });
  };

  const checkValidity = async () => {
    try {
      const { orderid } = userInfo;

      const response = await axios.get(
        `${configData.SERVER_URL}/ratings/checkvalidaty?orderid=${orderid}`,
      );

      if (response?.data?.isAnswered) {
        setSuccess(true);
      }
    } catch (ex) {
      setInvalid(true);
      console.log('ex', ex?.response?.data);
    }
  };

  const sendSurvey = async () => {
    try {
      //validate
      if (
        !userData.Bestillingsoplevelse ||
        !userData.Mad ||
        !userData['Main Rating'] ||
        !userData.Service ||
        !userData.Ventetid
      ) {
        return alert('Vælg venligst bedømmelser.');
      }

      const { orderid, restaurantid } = userInfo;
      const payload = {
        orderid,
        restaurant: restaurantid,
        data: userData,
      };

      const response = await axios.post(`${configData.SERVER_URL}/ratings/add`, payload);

      setSuccess(true);
      console.log('response', response);
    } catch (ex) {
      alert(ex?.response?.data || ex?.message || 'Encountered an error.');
      console.log('ex', ex?.response?.data);
    }
  };

  console.log('userInfo', userInfo);
  console.log('mainRating', mainRating);

  if (success) {
    return <SuccessSendRating />;
  }
  if (invalid) {
    return <InvalidMessage />;
  }

  return (
    <div className="_container container text-center">
      <div className="head">
        <h5>Tak for din bedømmelse af din oplevelse hos</h5>
        <h3>{userInfo?.restaurantname}</h3>
      </div>

      <div className="subhead">
        <p>
          Bedom din oplevelse ved at valge antallet af stjerner, hvor 5 sterner er den hojeste
          bedommelse.
        </p>

        <StarRatings
          category={mainRating}
          setCategories={setMainRating}
          textColor="#fff"
          userData={userData}
          setUserData={setUserData}
        />
      </div>

      <div className="userinfo">
        <h5>Vild du uddybe din oplevelse {userInfo?.name}?</h5>
      </div>

      <div className="categories">
        {categories.map((category, index) => (
          <div className={`category ${index % 2 == 0 ? 'odd' : 'even'}`}>
            <h5>{category.name}</h5>
            <StarRatings
              category={category}
              setCategories={setCategories}
              userData={userData}
              setUserData={setUserData}
            />
          </div>
        ))}
      </div>

      <div className="comments">
        <h5>Kommentarer?</h5>
        <p>
          Har du yderligere tanker eller feedback, du gerne vil dele? Dine kommentarer er meget
          værdsatte, da de hjælper os med at forstå dine behov og forbedre vores service
        </p>
        <textarea
          rows={8}
          value={userData?.comments}
          onChange={(e) =>
            setUserData({
              ...userData,
              comments: e.target.value,
            })
          }
        />
        <button className="mt-8 sendbtn" onClick={sendSurvey}>
          Send
        </button>
      </div>

      <div className="footer">
        <img src="https://heywaitr-staging.herokuapp.com/static/media/heywaitr.b08c1632.jpg" />

        <p className="mt-4">
          <b>Ordredetaljer</b> - Order ID: {userInfo?.refno} -{' '}
          {moment(userInfo?.orderdate || '').format('MMMM Do YYYY')}
        </p>
      </div>
    </div>
  );
}

function StarRatings(props) {
  const { textColor, category, userData, setUserData } = props;

  const ratings = [
    {
      value: 1,
      text: 'Dårlig',
    },
    {
      value: 2,
      text: 'Middelmådig',
    },
    {
      value: 3,
      text: 'God',
    },
    {
      value: 4,
      text: 'Meget god',
    },
    {
      value: 5,
      text: 'Fremragende',
    },
  ];

  const selectRate = (rate) => {
    setUserData({
      ...userData,
      [category.name]: rate.value,
    });
  };

  const shouldBeChecked = (rateValue) => {
    let selectedValue = userData?.[category.name];

    if (selectedValue >= rateValue) {
      return 'starbox-selected';
    }

    return '';
  };

  console.log('userData', userData);

  return (
    <div className="startcont my-4">
      {ratings.map((rate) => {
        return (
          <div>
            <div
              className={`starbox  ${shouldBeChecked(rate.value)} `}
              onClick={() => selectRate(rate)}
            >
              <span className={'ratenum star_' + rate.value}>{rate.value}</span>
              <i className="far fa-star" />
            </div>
            <div className="ratetxt" style={{ color: textColor || '#000' }}>
              {rate.text}
            </div>
          </div>
        );
      })}
    </div>
  );
}

function SuccessSendRating() {
  return (
    <div className="_container container container-success">
      <div className="successdiv">
        <img
          src="https://images.vexels.com/media/users/3/157931/isolated/preview/604a0cadf94914c7ee6c6e552e9b4487-curved-check-mark-circle-icon-by-vexels.png"
          width="70px"
        />
        Tak for din feedback!
      </div>
      <div className="footer text-center">
        <img src="https://heywaitr-staging.herokuapp.com/static/media/heywaitr.b08c1632.jpg" />
      </div>
    </div>
  );
}

function InvalidMessage() {
  return (
    <div className="_container container container-success">
      <div className="successdiv">
        <img src="https://static.thenounproject.com/png/45592-200.png" width="50px" />{' '}
        <span>Beklager, det ser ud til, at linket er ugyldigt eller allerede er udløbet.</span>
      </div>
      <div className="footer text-center">
        <img src="https://heywaitr-staging.herokuapp.com/static/media/heywaitr.b08c1632.jpg" />
      </div>
    </div>
  );
}

export default Survey;
export { StarRatings };
