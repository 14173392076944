export const counterReducer = (state = null, action) => {
    switch (action.type){
        case "INCREMENT":
            // cart: [{product:{id:1,name:'gio',quantity:40,notes:'allergy'}}]
            let temp=state;
            // console.log('gio    '); 
            // console.log(temp);
            temp.push(3);
            
            // let temp={product:{id:2,name:'gio2',quantity:40,notes:'allergy'}}
            return temp;
        default:
            return state;
    }
}