import initialData from './initialData';

export const loadState = () => {
    try { 
      const serializedState = localStorage.getItem('state');
      console.log(serializedState);
      if (serializedState === null) { 
        return initialData;
        // return undefined;
      }
      return JSON.parse(serializedState);
    } catch (err) { 
      return undefined;
    }
  }; 

  export const saveState = (state) => {
    try {
      const serializedState = JSON.stringify(state);
      localStorage.setItem('state', serializedState);
    } catch {
      // ignore write errors
    }
  };